//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    components: {},
    props: {
        bidSectionCode: {
            type: String,
            default: '',
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                bidSectionCode: '',
                settleRemark: '',
                otherRemark: '',
            },
        };
    },
    methods: {
        //获取结算设置
        queryData: function () {
            this.$get(
                `${this.$store.getters.myprojectUrl}/bidSection/${this.bidSectionCode}`,
                (data) => {
                    this.form = data;
                }
            );
        },
        //提交
        submit: function () {
            this.$post(
                `${this.$store.getters.myprojectUrl}/projectAudit/setSettleRate`,
                {
                    ...this.form,
                    bidSectionCode: this.bidSectionCode,
                },
                (data) => {
                    ShowMsg('提交成功', 'success');
                    this.cancle();
                },
                true
            );
        },

        cancle: function () {
            this.$emit('close');
        },
    },
    mounted: function () {
        this.queryData();
    },
};
