//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//






import audit from '@/views/myproject/views/projectAudit/audit'
export default {
    mixins: [  ],
    components:{audit},
    data: function(){
        return{
            pgData:{
                regionCode: '',
                auditStatus:'0',
            },
            tableData:[],
            tableHeight:window.innerHeight - 203,
            auditStatusList:[
                {value:'待审核',key:'0',type:'info'},
                {value:'已审核',key:'1',type:'success'},
                {value:'未通过',key:'2',type:'danger'},
            ],
            hebeiCityList:[
                // {
                //     label:"全部",
                //     value:"0",
                // },
                {
                    label:"石家庄市",
                    value:"130100",
                },
                {
                    label:"唐山市",
                    value:"130200",
                },
                {
                    label:"秦皇岛市",
                    value:"130300",
                },
                {
                    label:"邯郸市",
                    value:"130400",
                },
                {
                    label:"邢台市",
                    value:"130500",
                },
                {
                    label:"保定市",
                    value:"130600",
                },
                {
                    label:"张家口市",
                    value:"130700",
                },
                {
                    label:"承德市",
                    value:"130800",
                },
                {
                    label:"沧州市",
                    value:"130900",
                },
                {
                    label:"廊坊市",
                    value:"131000",
                },
                {
                    label:"衡水市",
                    value:"131100",
                }, 
            ],
            areaValueList:[],
            batchAuditList:[],//批量审核列表
            dialogTitle:'',
            auditDialog:false,
            identityList:[
                {value:'招标人',key:'1',type:''},
                {value:'招标代理',key:'2',type:''},
                {value:'其他',key:'9',type:''},
            ],
        }
    }, 
    computed:{
 
    },
    watch:{
        auditDialog: function(n, o){
            if(!n){
                this.doSearch();
            }
        },
    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },
        reload:function(){
            this.$refs.page.reload();
        },
        //审核
        goAudit:function(row){
        if(this.getQuery('token')){
                this.goto({
                    path: './detail',
                    query: {
                        tenderProjectCode: row.tenderProjectCode,
                        token:this.getQuery('token')
                    }
                })
            }else{
                this.goto({
                    path: './detail',
                    query: {
                        tenderProjectCode: row.tenderProjectCode
                    }
                }) 
            }
        },
        exportExexl:function(){
            if(this.tableData.length !=0){
                var search = toSearch({
                    auditStatus:this.pgData.auditStatus,
                    regionCode: this.pgData.regionCode,
                    tenderProjectName: this.pgData.tenderProjectName,
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token,
                    isExport: true,
                });
                downloader('/bhtong/myproject/projectAudit' + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }

        },
        //过滤字典数据
        filterDiyData:function(arr,val){
            let status =  arr.filter(function(item){
                return item.key == val ;
            })
            if(status.length > 0){
                return status[0]; //返回筛选的对象
            } else{
                return {};
            }
        },
        areaChange:function(val){
            this.pgData.regionCode = val.toString();
            var that = this;
            if (!val.includes('0') && val.length === that.hebeiCityList.length) {
                that.areaValueList.unshift('0')
            } else if (val.includes('0') && (val.length - 1) < that.hebeiCityList.length) {
                that.areaValueList = that.areaValueList.filter((item) => {
                    return item !== '0'
                })
            }
            if(this.areaValueList.includes('0')){
                this.pgData.regionCode = '0';
            }else{
                this.pgData.regionCode = this.areaValueList.toString();
            }
        },
        selectAll() {
            if (this.areaValueList.length < this.hebeiCityList.length) {
                this.areaValueList = []
                this.hebeiCityList.map((item) => {
                this.areaValueList.push(item.value)
            })
                this.areaValueList.unshift('0');
                this.pgData.regionCode = '0';
            } else {
                this.areaValueList = [];
                this.pgData.regionCode = '';
            }
        },
        removeTag(val) {
            if (val === '0') {
                this.areaValueList = [];
            }
        },

        selectionMore:function(val){
            let that = this;
            that.batchAuditList = []
            if(val.length > 0){
                val.forEach(item => {
                    that.batchAuditList.push(item.tenderProjectCode)   
                });
            }
            console.log(that.batchAuditList);
        },

        selectable:function(row, index){
            if(row.auditStatus === '0'){
                return true;
            }else{
                return false;
            }
        },
        //批量审核
        batchAudit:function(){
            if(this.batchAuditList.length == 0) return ShowMsg('请至少选择一个项目', 'warning');
            this.dialogTitle = '批量审核';
            this.auditDialog = true;
        },
    },
    mounted() {
       
        if(this.getQuery('regionCode')){
            this.pgData.regionCode = this.getQuery('regionCode');
            if(this.pgData.regionCode === '0'){
                this.selectAll();
            }else{
                this.areaValueList = this.pgData.regionCode.split(',');
            }
        }
    },
    created(){
        sessionStorage.removeItem('search[#/myproject/projectAudit/list]')
    }
}
