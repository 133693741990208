//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import audit from '@/views/myproject/views/projectAudit/audit'
import settlementSet from './components/settlement-set'

export default {
    components: {audit,settlementSet},
    props: {},
    data(){
        return{
            detailForm:{
                bidSectionList:[]
            },
            dialogTitle:'',
            auditDialog:false,
            settlementSetDialog:false,
            bidSectionCode:'',

            identityList:[
                {value:'招标人',key:'1',type:''},
                {value:'招标代理',key:'2',type:''},
                {value:'其他',key:'9',type:''},
            ],
            isReadonly: false,  //是否可以编辑
        }
    },
    computed: {
        tenderProjectCode:function(){
            return this.getQuery('tenderProjectCode')
        },
        tenderProjectCodeArr:function(){
            return [this.getQuery('tenderProjectCode')]
        },
    },
    watch:{
        auditDialog: function(n, o){
            if(!n){
                this.getDetail();
            }
        },
        settlementSetDialog: function(n, o){
            if(!n){
                this.getDetail();
            }
        },
    },
    methods: {
        getDetail:function(){
            this.$get(this.$store.getters.myprojectUrl + '/projectAudit/'+this.tenderProjectCode,{
            }, function(data) {
                this.detailForm = data;
            })
        },
        //导出
        exportList:function(){
            if(this.tableData.length !=0){
                var search = toSearch({
                    isExport: true,
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
                });
                window.open('/bhtong/settmg/hcgSettItem/' + this.rowguid + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },
        //返回
        goBack:function(){
            this.$router.go(-1);
        },
        //审核
        audit:function(){
            this.dialogTitle = '审核';
            this.auditDialog = true;
        },
        openFile:function(row){
            if(!row.tenderFileUrl) {
                return ShowMsg('文件不存在','warning');
            }
            if(row.tenderFileUrl.substr(-4) == 'docx' || row.tenderFileUrl.substr(-4) == '.doc'){
                window.open("https://view.officeapps.live.com/op/view.aspx?src=" + row.tenderFileUrl ,'_blank');
            }else{
                window.open(row.tenderFileUrl,'_blank');
            }
        },
        //导出
        exportExexl:function(){
            if(this.detailForm.bidSectionList.length > 0){
                var search = toSearch({
                    auditStatus:this.pgData.auditStatus,
                    regionCode: this.pgData.regionCode,
                    tenderProjectName: this.pgData.tenderProjectName,
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token,
                    isExport: true,
                });
                downloader('/bhtong/myproject/projectAudit' + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }

        },
        opensett:function(row){
            this.isReadonly = this.detailForm.auditStatus == 0 ? false : true;
            this.bidSectionCode = row.bidSectionCode;
            this.dialogTitle = '结算设置';
            this.settlementSetDialog = true;
        },


    },
    mounted:function(){
        this.getDetail();   
    }
}
