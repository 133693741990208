//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    props: {


    },
    components: {},
    data: function () {
        return {
            list:[
                {value:'1',key:1000},
                {value:'2',key:4000},
                {value:'3',key:1000},
                {value:'4',key:100},
            ],
            labelwidth:0,
            lineOption:{
                xAxis: {
                    show: false,
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    show: false
                },
                series: [
                    {
                        itemStyle : { normal: {label : {show: true}}},
                        data: [1500, 2100, 2500, 0, 12, 1592, 22],
                        type: 'line'
                    }
                ]
            },
        }
    },
    computed: {

    },
    watch: {


    },

    methods: {
        getWidth:function(){
            let div =document.querySelector('.test')
            this.labelwidth =  div.clientWidth 
            console.log(this.labelwidth);
        },
        getmax:function(arr){
            let max = 0
            for (let i = 0; i < arr.length - 1; i++) {
                max = max < arr[i].key ? arr[i].key : max
            }
            return max + 500
        },



    },
    mounted () {
        this.getWidth()

    }
}
