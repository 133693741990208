//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    props: {
        tenderProjectCode:{
            type:Array,
            default:function(){
                return () =>[]
            },
        },

    },
    components: {},
    data: function () {
        return {
            form: {
                tenderProjectCodes:this.tenderProjectCode,
                auditStatus:'',
                auditRemark:'',
            },
        }
    },
    computed: {

    },
    watch: {

    },

    methods: {
        cancle: function () {
            this.$emit('close')
        },
        submit: function () {
            let that = this;
            that.$put(`${that.getGetters('myprojectUrl')}/projectAudit/audit`,this.form ,function(data){
                ShowMsg('提交成功','success');
                that.cancle();
            },true)
        },

    },
    mounted () {

    }
}
